/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import Swal from 'sweetalert2';

const baseURL = process.env.REACT_APP_API_URL;

const REST = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

// 1. 요청 인터셉터 2개의 콜백함수
REST.interceptors.request.use(
  async config => {
    // 요청 성공 직전 호출됩니다.
    // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
    const originalRequest = config;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// 2. 응답 인터셉터 2개의 콜백함수
REST.interceptors.response.use(
  response => {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다.
        .then() 으로 이어집니다.
        */
    return response;
  },
  async error => {
    try {
      const { config, response } = error;
      const originalRequest = config;
      let result = response;

      // response이 Blob으로 왔을 경우  response data to json
      // [[
      if (
        response.data instanceof Blob &&
        response.data.type === 'application/json'
      ) {
        const responseData = await response.data.text();
        const responseJson =
          typeof responseData === 'string'
            ? JSON.parse(responseData)
            : responseData;
        result = responseJson;
      }
      // ]]

      const errorMessage = result.data.message;

      if (result.data.intended) {
        return result;
      }

      // 에러 메세지 Swal
      Swal.fire({
        html: errorMessage,
        confirmButtonText: '확인',
      });
    } catch (err) {
      console.log(err, '-err');
    }
    return Promise.reject(error);
  },
);

export default REST;
