import counterReducer from './counterSlice';
import profileSlice from './profileSlice';
import placeSlice from './placeSlice';

const count = counterReducer;
const profile = profileSlice;
const place = placeSlice;
export default {
  count,
  profile,
  place,
};
