import REST from '@common/rest';

const baseURL = process.env.REACT_APP_API_URL;
const API_VERSION = 'api/v1';

export const DOMAIN_SURVEY = `${API_VERSION}/survey`;

// PORTAL_006 ::  설문조사 조회
export const getSurvey = surveyId => {
  return REST.get(`${baseURL + DOMAIN_SURVEY}/${surveyId}`);
};

// PORTAL_007 :: 설문조사 제출
export const submitSurvey = data => {
  return REST.post(`${baseURL + DOMAIN_SURVEY}/submit`, data);
};
