/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profileNm: '',
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profileNm = action.payload.profileNm;
    },
    getProfile: (state, action) => {
      const profile = {
        profileNm: state.profileNm,
      };
      action.payload = profile;
    },
    removeProfile: state => {
      state.profileNm = initialState.profileNm;
    },
  },
});

// Action creators are generated for each case reducer function
export const profileAction = profileSlice.actions;

export default profileSlice.reducer;
