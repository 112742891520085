/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import BeatLoader from 'react-spinners/BeatLoader';
import { Button, Modal, Ratio } from 'react-bootstrap';
import { images } from '@assets';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useParams } from 'react-router-dom';
import { store } from '@common/redux/store';
import { profileAction } from '@reducers/profileSlice';
import { placeAction } from '@reducers/placeSlice';
import Swal from 'sweetalert2';

// 홈 화면 버튼 설명 모달 - icon
import HouseIcon from '@assets/svg/info/house.svg';
import WifiIcon from '@assets/svg/info/wifi.svg';

import { getPromotions, getPromotionsDetail } from './crud';
import Utils from '../../common/Utils';
import RESULT_CODE from '../../common/ResultCode';
import RouterPath from '../../common/RouterPath';

SwiperCore.use([Navigation, Autoplay]);

const override = {
  display: 'block',
  margin: 'auto',
};

// 홈 화면 버튼 설명 모달 - CSS
const buttonInfo = {
  display: 'flex',
  justifyContent: 'center',
  padding: '20px 0',
  margin: '0px 0',
  width: '38px',
  height: '38px',
  backgroundColor: 'rgba(255, 255, 0, 1)',
  border: 'hidden',
  marginRight: '1em',
};

export default React.memo(function Home(props) {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const [loading, setLoading] = useState(false); // 로딩 스피너

  const [showInfo, setShowInfo] = useState(true);

  const { profileNm } = useParams();
  // 통광고
  const [fullPagePrmShow, setFullPagePrmShow] = useState(false);

  // 프로모션 상세페이지로 이동
  const goPrmDetail = prmIdx => {
    props.history.push({ pathname: `/prmDetail/${prmIdx}` });
  };

  // 프로모션 링크로 이동(CPC 카운팅 기능 추가_240521)
  const goPrmLink = item => {
    const addCountCPC = async () => {
      await getPromotionsDetail(item.promoId);
    };
    addCountCPC();
    window.open(item.url, '_self');
  };

  // 설문조사 페이지
  const goSurvey = prmIdx => {
    props.history.push({ pathname: `/Survey/${prmIdx}` });
  };
  const [promoFull, setPromoFull] = useState();
  const [listSlide, setListSlide] = useState([]);
  const [repeatBanners, setRepeatBanners] = useState([
    {
      bar: [],
      box: [],
    },
    {
      bar: [],
      box: [],
    },
    {
      bar: [],
      box: [],
    },
  ]);

  useEffect(() => {
    store.dispatch(profileAction.setProfile({ profileNm }));
    getProfileData();
  }, [profileNm]);

  const getProfileData = async () => {
    setLoading(true);
    const { data } = await getPromotions(profileNm);
    if (data.code === 200) {
      setPromoFull(data.data.promoFull);
      setFullPagePrmShow(!!data.data.promoFull);
      setListSlide(data.data.listSlide);
      const tempRepeatBanners = [
        {
          bar: data.data.listBar1,
          box: data.data.listBox1,
        },
        {
          bar: data.data.listBar2,
          box: data.data.listBox2,
        },
        {
          bar: data.data.listBar3,
          box: data.data.listBox3,
        },
      ];
      setRepeatBanners(tempRepeatBanners);
    } else {
      Swal.fire({
        text: RESULT_CODE[`CODE_${data.code}`],
        confirmButtonText: '확인',
      }).finally(() => {
        props.history.push(RouterPath.Error);
      });
    }
    setLoading(false);
  };

  const getDiscountValue = data => {
    switch (data.discountType) {
      case 'RATE':
        return Utils.calculatePercentage(data.discountValue, 1);
      default:
        return `${Utils.changeNumberComma(data.discountValue)}원`;
    }
  };

  // 홈에서 플레이스로 이동 => 기본값 "AP 위경도"
  useEffect(() => {
    store.dispatch(
      placeAction.setPlace({
        placeLatLng: {
          ap: true,
          latitude: null,
          longitude: null,
        },
      }),
    );
  }, []);

  return (
    <main id="home">
      {/* 메인 비쥬얼 */}
      <section>
        <div className="swiper-wrap visual mb-3">
          <Swiper
            loop
            speed={800}
            spaceBetween={12}
            // slidesOffsetAfter={12}
            threshold={30}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, EffectFade]}
            effect="slide"
            className="swiper-box"
          >
            {listSlide.map((item, idx) => {
              return (
                <SwiperSlide
                  key={`visual-${item.promoId}`}
                  data-swiper-autoplay={1500}
                >
                  <Ratio
                    // aspectRatio="70x93"
                    onClick={
                      item.urlMoveYn === 'N' || item.url === null
                        ? () => goPrmDetail(item.promoId)
                        : // : () => window.open(item.url, '_self')
                          () => goPrmLink(item)
                    }
                  >
                    <div
                      className="img-box"
                      style={{
                        backgroundImage: `url(${Utils.fileViewUrl(
                          item.bannerPath,
                        )})`,
                      }}
                    >
                      {item.couponId && (
                        <div className="grid-label">
                          <p className="sale-won">{getDiscountValue(item)}</p>
                          <p className="sale-text">
                            할인
                            <br />
                            쿠폰
                          </p>
                        </div>
                      )}
                    </div>
                  </Ratio>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>

      {/* 반복될 섹션 */}
      {repeatBanners.length > 0 &&
        repeatBanners.map((sec, secIdx) => {
          return (
            <section key={`sec-${secIdx}`}>
              {sec.bar && sec.bar.length > 0 && (
                <div className="swiper-wrap landscape">
                  <Swiper
                    loop
                    speed={800}
                    spaceBetween={12}
                    // slidesOffsetAfter={12}
                    threshold={30}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation, EffectFade]}
                    effect="slide"
                    className="swiper-box"
                  >
                    {sec.bar.map(item => {
                      return (
                        <SwiperSlide
                          key={`prm-${item.promoId}`}
                          data-swiper-autoplay={3000}
                          onClick={
                            item.urlMoveYn === 'N' || item.url === null
                              ? () => goPrmDetail(item.promoId)
                              : // : () => window.open(item.url, '_self')
                                () => goPrmLink(item)
                          }
                        >
                          <Ratio aspectRatio="7x2">
                            <div
                              className="img-box"
                              style={{
                                backgroundImage: `url(${Utils.fileViewUrl(
                                  item.bannerPath,
                                )})`,
                              }}
                            >
                              {item.couponId && (
                                <div className="grid-label">
                                  <p className="sale-won">
                                    {getDiscountValue(item)}
                                  </p>
                                  <p className="sale-text">
                                    할인
                                    <br />
                                    쿠폰
                                  </p>
                                </div>
                              )}
                            </div>
                          </Ratio>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              )}
              {sec.box && sec.box.length > 0 && (
                <div className="swiper-wrap ports">
                  <Swiper
                    slidesPerView={2.5}
                    spaceBetween={12}
                    threshold={30}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    className="swiper-box"
                  >
                    {sec.box.map(item => {
                      return (
                        <SwiperSlide
                          key={`prm-${item.promoId}`}
                          data-swiper-autoplay={5000}
                          onClick={
                            item.urlMoveYn === 'N' || item.url === null
                              ? () => goPrmDetail(item.promoId)
                              : // : () => window.open(item.url, '_self')
                                () => goPrmLink(item)
                          }
                        >
                          <Ratio aspectRatio="7x10">
                            <div
                              className="img-box"
                              style={{
                                backgroundImage: `url(${Utils.fileViewUrl(
                                  item.bannerPath,
                                )})`,
                              }}
                            >
                              {item.couponId && (
                                <div className="grid-label">
                                  <p className="sale-won">
                                    {getDiscountValue(item)}
                                  </p>
                                  <p className="sale-text">
                                    할인
                                    <br />
                                    쿠폰
                                  </p>
                                </div>
                              )}
                            </div>
                          </Ratio>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              )}
            </section>
          );
        })}

      {/* 닫기 */}
      {/* <section className="p-3">
        <Button variant="dark" size="lg" className="w-100 btn-connect-wifi">
          닫기
        </Button>
      </section> */}

      {/* 통광고 */}
      <Modal fullscreen show={fullPagePrmShow} id="modal-fullpage-prm">
        <Modal.Body
          className="img-box"
          style={{
            backgroundImage: `url(${Utils.fileViewUrl(
              promoFull && promoFull.bannerPath,
            )})`,
          }}
        >
          {promoFull && promoFull.surveyId && (
            <div className="btn-sur">
              <Button
                variant="dark"
                size="lg"
                // surveyId
                onClick={() =>
                  props.history.push({
                    pathname: '/survey',
                    state: { surveyId: promoFull.surveyId },
                  })
                }
              >
                설문조사
              </Button>
            </div>
          )}
          <div className="btn-area w-100">
            <Button
              variant="dark"
              size="lg"
              onClick={() => setFullPagePrmShow(false)}
            >
              닫기
            </Button>
            <Button
              variant="dark"
              size="lg"
              onClick={
                promoFull &&
                (promoFull.urlMoveYn === 'N' || promoFull.url === null)
                  ? () => goPrmDetail(promoFull.promoId)
                  : // : () => window.open(promoFull.url, '_self')
                    () => goPrmLink(promoFull)
              }
            >
              자세히 보기
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* 캡티브포탈 설명 */}
      <Modal
        show={showInfo}
        centered={true}
        id="modal-info-prm"
        onHide={() => setShowInfo(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={Utils.fileViewUrl(`/promo/${profileNm}.png`)}
              width={150}
              alt={'Welcome'}
              title={'Welcome'}
              onError={e => {
                e.target.src = images.ci;
              }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1em',
            }}
          >
            <Button style={buttonInfo}>
              <HouseIcon height="18px" width="18px" />
            </Button>
            {t('welcomeModalHome')}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button style={buttonInfo}>
              <WifiIcon height="18px" width="18px" />
            </Button>
            {t('welcomeModalWifi')}
          </div>
        </Modal.Body>
      </Modal>

      {loading && (
        <div
          style={{ placeItems: 'center', display: 'grid', height: '100dvh' }}
        >
          <div style={{ textAlign: 'center' }}>
            <img
              src={Utils.fileViewUrl(`/promo/${profileNm}.png`)}
              width={150}
              alt={'Loading...'}
              title={'Loading...'}
              onError={e => {
                e.target.src = images.ci;
              }}
            />
            <div style={{ marginBottom: '1em' }}>Public Wi-Fi</div>
            <BeatLoader
              color="#337ab7"
              loading={loading}
              cssOverride={override}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      )}
    </main>
  );
});

const dummyVisuals = [
  {
    idx: 1,
    url: null,
    img: images.land3,
    delay: 5000,
  },
  {
    idx: 2,
    url: null,
    img: images.land4,
    delay: 2000,
  },
];
const repeatBanners = [
  {
    lnds: [
      {
        idx: 3,
        url: 'http://www.naver.com',
        img: images.land1,
        delay: 2000,
      },
      {
        idx: 4,
        url: 'http://www.naver.com',
        img: images.land2,

        delay: 5000,
      },
    ],
    prts: [
      {
        idx: 5,
        url: null,
        img: images.port1,
      },
      {
        idx: 6,
        url: null,
        img: images.port1,
      },
      {
        idx: 7,
        url: null,
        img: images.port1,
      },
      {
        idx: 8,
        url: null,
        img: images.port1,
      },
      {
        idx: 9,
        url: null,
        img: images.port1,
      },
      {
        idx: 10,
        url: null,
        img: images.port1,
      },
    ],
  },
  {
    lnds: [
      {
        idx: 11,
        url: null,
        img: images.land2,
        delay: 5000,
      },
      {
        idx: 12,
        url: null,
        img: images.land3,
        delay: 5000,
      },
    ],
    prts: [
      {
        idx: 13,
        url: null,
        img: images.port2,
      },
      {
        idx: 14,
        url: null,
        img: images.port2,
      },
      {
        idx: 15,
        url: null,
        img: images.port2,
      },
      {
        idx: 16,
        url: null,
        img: images.port2,
      },
      {
        idx: 17,
        url: null,
        img: images.port2,
      },
      {
        idx: 18,
        url: null,
        img: images.port2,
      },
    ],
  },
  {
    lnds: [
      {
        idx: 19,
        url: 'http://www.naver.com',
        img: images.land3,

        delay: 2000,
      },
      {
        idx: 20,
        url: 'http://www.naver.com',
        img: images.land4,

        delay: 5000,
      },
    ],
    prts: [
      {
        idx: 21,
        url: 'http://www.naver.com',
        img: images.port3,
      },
      {
        idx: 22,
        url: 'http://www.naver.com',
        img: images.port3,
      },
      {
        idx: 23,
        url: 'http://www.naver.com',
        img: images.port3,
      },
      {
        idx: 24,
        url: 'http://www.naver.com',
        img: images.port3,
      },
      {
        idx: 25,
        url: 'http://www.naver.com',
        img: images.port3,
      },
      {
        idx: 26,
        url: 'http://www.naver.com',
        img: images.port3,
      },
    ],
  },
];
