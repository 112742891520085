/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Ratio, Badge } from 'react-bootstrap';
import { images } from '@assets';
import { RouterPath } from '@common';

// icon
import ArrowBackIcon from '@assets/svg/arrow_back_black_24dp.svg';
import ShareIcon from '@assets/svg/share_black_24dp.svg';
import PlaceIcon from '@assets/svg/place_black_24dp.svg';
import CallIcon from '@assets/svg/call_black_24dp.svg';
import CalendarMonthIcon from '@assets/svg/calendar_month_black_24dp.svg';
import HomeIcon from '@assets/svg/home_black_24dp.svg';
import DescriptionIcon from '@assets/svg/description_black_24dp.svg';
import DownloadIcon from '@assets/svg/download_black_24dp.svg';
import ContentCopyIcon from '@assets/svg/content_copy_FILL0_wght400_GRAD0_opsz24.svg';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/thumbs';
import { useHistory, useParams, NavLink } from 'react-router-dom';
import { store } from '@common/redux/store';
import { profileAction } from '@reducers/profileSlice';
import { placeAction } from '@reducers/placeSlice';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getPromotionsDetail } from './crud';
import Utils from '../../common/Utils';
import CustomSwal from '../../components/CustomSwal';

SwiperCore.use([Thumbs, FreeMode]);

export default React.memo(function PrmDetail(props) {
  const history = useHistory();
  const componentRef = useRef();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { idx } = useParams();
  const [prmDetailData, setPrmDetailData] = useState({});

  const gerPrmDetailData = async () => {
    const { data } = await getPromotionsDetail(idx);
    if (data.code === 200) {
      setPrmDetailData(data.data);
      store.dispatch(
        profileAction.setProfile({ profileNm: data.data.profileNm }),
      );
      store.dispatch(
        placeAction.setPlace({
          placeLatLng: {
            ap: false,
            latitude: data.data.latitude,
            longitude: data.data.longitude,
          },
        }),
      );
    }
  };

  const share = () => {
    if (navigator.share) {
      navigator.share({
        url: window.location.href,
      });
    } else {
      Swal.fire({
        text: '사용할 수 없는 브라우저 입니다.',
        confirmButtonText: '확인',
      });
    }
  };

  const getDiscountValue = (discountType, discountValue) => {
    switch (discountType) {
      case 'RATE':
        return Utils.calculatePercentage(discountValue, 1);
      default:
        return `${Utils.changeNumberComma(discountValue)}원`;
    }
  };

  const copyClipBoardSwal = title =>
    CustomSwal.confirmSwal({
      title: `${title} 복사 완료!`,
      text: '필요한 곳에 붙여 넣기하세요~',
      icon: 'success',
      // confirmText: '확인', // 확인 버튼 텍스트 설정 (선택 사항)
      // cancelText: '취소', // 취소 버튼 텍스트 설정 (선택 사항)
      // then: isConfirmed => {
      //   if (isConfirmed) {
      //     console.log(`${text}`);
      //   } else {
      //     console.log('취소');
      //   }
      // },
    });

  useEffect(() => {
    if (Number(idx) && Number.isInteger(Number(idx))) {
      gerPrmDetailData();
    } else {
      Swal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
      }).finally(() => {
        history.goBack();
      });
    }
  }, [idx]);

  return (
    <main id="prm-detail">
      <header>
        <Button variant="icon" onClick={() => props.history.goBack()}>
          {/* <i className="material-icons">arrow_back</i> */}
          <ArrowBackIcon height="20px" width="20px" />
        </Button>
        <h3>{prmDetailData.title}</h3>
        <Button variant="icon" onClick={() => share()}>
          {/* <i className="material-icons">share</i> */}
          <ShareIcon height="20px" width="20px" />
        </Button>
      </header>
      <section>
        <Swiper
          loop
          speed={400}
          spaceBetween={0}
          threshold={30}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[Thumbs]}
          className="swiper-box"
        >
          {prmDetailData &&
            prmDetailData.imgList &&
            prmDetailData.imgList.length > 0 &&
            prmDetailData.imgList
              .sort((a, b) => a.orderNo - b.orderNo)
              .map((item, i) => {
                return (
                  <SwiperSlide key={`visual-${i}`}>
                    <Ratio aspectRatio="35x19">
                      <div
                        className="img-box"
                        style={{
                          backgroundImage: `url(${Utils.fileViewUrl(
                            item.filePath,
                          )})`,
                        }}
                      />
                    </Ratio>
                  </SwiperSlide>
                );
              })}
        </Swiper>
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={4}
          slidesPerView={4}
          freeMode
          watchSlidesProgress
          modules={[FreeMode, Thumbs]}
          className="swiper-box pt-1"
        >
          {prmDetailData.imgList &&
            prmDetailData.imgList.length > 0 &&
            prmDetailData.imgList
              .sort((a, b) => a.orderNo - b.orderNo)
              .map((item, i) => {
                return (
                  <SwiperSlide key={`visual-${i}`}>
                    <Ratio aspectRatio="35x19">
                      <div
                        onClick={() => {
                          setThumbsSwiper(Utils.fileViewUrl(item.filePath));
                        }}
                        className="img-box"
                        style={{
                          backgroundImage: `url(${Utils.fileViewUrl(
                            item.filePath,
                          )})`,
                        }}
                      />
                    </Ratio>
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </section>
      <section className="info">
        <div className="title">
          <div className="flex-between">
            <h6>INFO</h6>
            <Badge>{prmDetailData.promoCategory}</Badge>
          </div>
        </div>
        <div className="dl-box align-start">
          <dl>
            <dt>
              {/* <i className="material-icons">place</i> */}
              <PlaceIcon height="20px" width="20px" />
            </dt>
            <dd style={{ cursor: 'pointer' }}>
              <div>
                <NavLink to={RouterPath.Place}>
                  <span>{prmDetailData.address}</span>
                </NavLink>

                <CopyToClipboard
                  text={prmDetailData.address}
                  onCopy={() => {
                    copyClipBoardSwal('주소');
                  }}
                >
                  <Button variant="icon">
                    <ContentCopyIcon height="20px" width="20px" />
                  </Button>
                </CopyToClipboard>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>
              {/* <i className="material-icons">call</i> */}
              <CallIcon height="20px" width="20px" />
            </dt>
            <dd>
              <CopyToClipboard
                style={{ cursor: 'pointer' }}
                text={prmDetailData.tel}
                onCopy={() => {
                  copyClipBoardSwal('전화번호');
                }}
              >
                <div>
                  <span>{prmDetailData.tel}</span>
                  <Button variant="icon">
                    <ContentCopyIcon height="20px" width="20px" />
                  </Button>
                </div>
              </CopyToClipboard>
            </dd>
          </dl>
          {prmDetailData.openDate || prmDetailData.closeDate ? (
            <dl>
              <dt>
                {/* <i className="material-icons">calendar_month</i> */}
                <CalendarMonthIcon height="20px" width="20px" />
              </dt>
              <dd>
                {prmDetailData.openDate && prmDetailData.closeDate && (
                  <>
                    <Moment date={prmDetailData.openDate} format="YYYY-MM-DD" />
                    <span> ~ </span>
                    <Moment
                      date={prmDetailData.closeDate}
                      format="YYYY-MM-DD"
                    />
                  </>
                )}
                {prmDetailData.openDate && !prmDetailData.closeDate && (
                  <>
                    <Moment date={prmDetailData.openDate} format="YYYY-MM-DD" />{' '}
                    <span> ~ </span>
                  </>
                )}
                {!prmDetailData.openDate && prmDetailData.closeDate && (
                  <>
                    <span> ~ </span>
                    <Moment
                      date={prmDetailData.closeDate}
                      format="YYYY-MM-DD"
                    />
                  </>
                )}
              </dd>
            </dl>
          ) : null}
          {prmDetailData.url && (
            <dl>
              <dt>
                {/* <i className="material-icons">home</i> */}
                <HomeIcon height="20px" width="20px" />
              </dt>
              {/* <dd>{prmDetailData.url}</dd> */}
              <dd>
                <CopyToClipboard
                  style={{ cursor: 'pointer' }}
                  text={prmDetailData.url}
                  onCopy={() => {
                    copyClipBoardSwal('URL');
                  }}
                >
                  <div>
                    <span>{prmDetailData.url}</span>
                    <Button variant="icon">
                      <ContentCopyIcon height="20px" width="20px" />
                    </Button>
                  </div>
                </CopyToClipboard>
              </dd>
            </dl>
          )}
          <dl>
            <dt>
              {/* <i className="material-icons">description</i> */}
              <DescriptionIcon height="20px" width="20px" />
            </dt>
            <dd
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {prmDetailData.description}
            </dd>
          </dl>
        </div>
        {prmDetailData.couponId && (
          <div className="coupon-section">
            <h4>할인쿠폰</h4>
            <div
              className="coupon-box"
              ref={componentRef}
              onClick={() => {
                Utils.handleDownloadClick(componentRef, prmDetailData.couponNm);
              }}
            >
              <div className="won-top" />
              <div className="coupon-title">
                <p>{prmDetailData.couponNm}</p>
              </div>
              <div className="grid-down">
                <p>
                  {getDiscountValue(
                    prmDetailData.discountType,
                    prmDetailData.discountValue,
                  )}
                </p>
                {/* <i className="material-icons right-icons">download</i> */}
                <DownloadIcon height="20px" width="20px" />
              </div>
              <div className="flex-day">
                <p>{prmDetailData.couponStartDate}</p>~
                <p>{prmDetailData.couponEndDate}</p>
              </div>
              <div className="won-bot" />
            </div>
            <p className="coupon-text">
              결제 시 해당 이미지를 가맹점에 제시하면,
              <br />
              해당 쿠폰 금액만큼 차감 결제됩니다.
            </p>
          </div>
        )}
      </section>
    </main>
  );
});

const dummyBanners = [images.land1, images.land2, images.land3, images.land4];
