// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 번역 리소스 정의
const resources = {
  ko: {
    translation: {
      welcomeModalHome: '버튼을 누르면 홈 화면으로 이동합니다.',
      welcomeModalWifi: '버튼을 누르면 무료 인터넷을 사용할 수 있습니다.',
      home: '홈',
      place: '플레이스',
      notice: '안내',
      language: '언어(Language)',
      languageModalTitle: '언어 변경',
    },
  },
  en: {
    translation: {
      welcomeModalHome: 'You can go to the home screen by pressing the button.',
      welcomeModalWifi: 'You can use the free Internet by pressing the button.',
      home: 'Home',
      place: 'Place',
      notice: 'Notice',
      language: 'English',
      languageModalTitle: 'Language Configuration',
    },
  },
  // 중국어 정보: https://blog.naver.com/special0227/220443302685
  zh: {
    translation: {
      welcomeModalHome: '按下按钮就会转到主页。',
      welcomeModalWifi: '按下按钮就可以免费上网了。',
      home: '键',
      place: '定位',
      notice: '服务条款', // 서비스약관
      language: '中文',
      languageModalTitle: '语言更改',
    },
  },
  ja: {
    translation: {
      welcomeModalHome: 'ボタンを押すとホーム画面に移動します。',
      welcomeModalWifi: 'ボタンを押すと、無料でインターネットを利用できます。',
      home: 'ホーム',
      place: '現在地', // 현 위치
      notice: '利用規約', // 이용 약관
      language: '日本語',
      languageModalTitle: '言語変更',
    },
  },
  es: {
    translation: {
      welcomeModalHome: 'Puede ir a la inicio principal pulsando el botón.',
      welcomeModalWifi: 'Puede utilizar Internet gratuito pulsando el botón.',
      home: 'Inicio', // 시작
      place: 'Posición actual', // 현재 위치
      notice: 'notificación',
      language: 'español',
      languageModalTitle: 'Configuración de idioma',
    },
  },
};

// i18next 초기화
i18n
  .use(initReactI18next) // react-i18next 바인딩
  .init({
    resources,
    lng: 'ko', // 기본 언어 설정
    fallbackLng: 'ko', // 기본 언어로 돌아가기

    interpolation: {
      escapeValue: false, // React는 기본적으로 XSS를 방지합니다.
    },
  });

export default i18n;
