import REST from '@common/rest';

const baseURL = process.env.REACT_APP_API_URL;
const API_VERSION = 'api/v1';

export const DOMAIN_HOME = `${API_VERSION}/home`;
export const DOMAIN_COMMON = `${API_VERSION}/common`;

// PORTAL_001 ::  홈 광고 리스트 조회
export const getPromotions = profileNm => {
  return REST.get(`${baseURL + DOMAIN_HOME}/promotions/${profileNm}`);
};

// PORTAL_001 ::  홈 광고 리스트 조회
export const getPromotionsDetail = promoId => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promotion/${promoId}`);
};
