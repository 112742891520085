import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useLocation, NavLink, Link } from 'react-router-dom';
import { ListGroup, Modal, Nav } from 'react-bootstrap';
import { RouterPath } from '@common';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import HomeIcon from '@assets/svg/home_black_24dp.svg';
// import PlaceIcon from '@assets/svg/place_black_24dp.svg';
import PlaceIcon from '@assets/svg/dining_black_24dp.svg';
// import QRIcon from '@assets/svg/qr_code_2_black_24dp.svg';
import InfoIcon from '@assets/svg/info_24dp_.svg';
import LanguageIcon from '@assets/svg/language_24dp.svg';

export default React.memo(function Navi() {
  const location = useLocation();
  const profile = useSelector(state => {
    return state.profile;
  });

  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const buttonStyle = {
    width: '20px',
    height: '20px',
    borderRadius: '20%',
    borderWidth: '1px',
    // border: 'none',
    // border: '2px solid #999999',
    color: '#999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    cursor: 'pointer',
  };

  const languageStyle = {
    width: '100%',
    height: '3em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <>
      <Nav as="nav">
        <Nav.Item>
          <Link
            to={`${RouterPath.Home}/${profile.profileNm && profile.profileNm}`}
            className={classNames({
              active: location.pathname.includes('/access'),
            })}
          >
            {/* <i className="material-icons">home</i> */}
            {location.pathname.includes('/access') ? (
              <HomeIcon fill="#fff" height="20px" width="20px" />
            ) : (
              <HomeIcon fill="#999999" height="20px" width="20px" />
            )}
            {/* <span>홈</span> */}
            <span>{t('home')}</span>
          </Link>
        </Nav.Item>
        <Nav.Item>
          <NavLink to={RouterPath.Place}>
            {/* <i className="material-icons">place</i> */}
            {/* {location.pathname === '/place' ? (
            <PlaceIcon fill="#fff" height="20px" width="20px" />
          ) : (
            <PlaceIcon fill="#999999" height="20px" width="20px" />
          )} */}
            {location.pathname === '/place' ? (
              <PlaceIcon fill="#fff" height="20px" width="20px" />
            ) : (
              <PlaceIcon fill="#999999" height="20px" width="20px" />
            )}
            {/* <span>플레이스</span> */}
            <span>{t('place')}</span>
          </NavLink>
        </Nav.Item>
        {/* <Nav.Item>
        <NavLink to={RouterPath.Qrcode}>
          {location.pathname === '/qrcode' ? (
            <QRIcon fill="#fff" height="20px" width="20px" />
          ) : (
            <QRIcon fill="#999999" height="20px" width="20px" />
          )}
          <span>QR코드</span>
        </NavLink>
      </Nav.Item> */}
        {/* <Nav.Item>
          <NavLink to={RouterPath.Notice}>
            {location.pathname === '/notice' ? (
              <InfoIcon fill="#fff" height="20px" width="20px" />
            ) : (
              <InfoIcon fill="#999999" height="20px" width="20px" />
            )}
            <span>{t('notice')}</span>
          </NavLink>
        </Nav.Item> */}
        <Nav.Item>
          <NavLink to={RouterPath} onClick={() => setShowLanguageModal(true)}>
            {showLanguageModal ? (
              <LanguageIcon fill="#fff" height="20px" width="20px" />
            ) : (
              <LanguageIcon fill="#999999" height="20px" width="20px" />
            )}
            {/* <button
              type="button"
              style={buttonStyle}
              onClick={() => changeLanguage('ko')}
            >
              KR/US/CN/JP
            </button> */}
            {/* <button
              type="button"
              style={buttonStyle}
              onClick={() => changeLanguage('en')}
            >
              EN
            </button> */}
            <span
              style={
                showLanguageModal ? { color: '#fff' } : { color: '#999999' }
              }
            >
              {t('language')}
            </span>
          </NavLink>
        </Nav.Item>
      </Nav>
      <Modal
        show={showLanguageModal}
        centered
        id="modal-set-language"
        onHide={() => setShowLanguageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('languageModalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item
              active={i18n.language === 'ko'}
              onClick={() => changeLanguage('ko')}
            >
              <div style={languageStyle}>한국어</div>
            </ListGroup.Item>
            <ListGroup.Item
              active={i18n.language === 'en'}
              onClick={() => changeLanguage('en')}
            >
              <div style={languageStyle}>English</div>
            </ListGroup.Item>
            <ListGroup.Item
              active={i18n.language === 'zh'}
              onClick={() => changeLanguage('zh')}
            >
              <div style={languageStyle}>中文</div>
            </ListGroup.Item>
            <ListGroup.Item
              active={i18n.language === 'ja'}
              onClick={() => changeLanguage('ja')}
            >
              <div style={languageStyle}>日本語</div>
            </ListGroup.Item>
            <ListGroup.Item
              active={i18n.language === 'es'}
              onClick={() => changeLanguage('es')}
            >
              <div style={languageStyle}>español</div>
            </ListGroup.Item>
          </ListGroup>
          {/* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1em',
            }}
          >
            버튼을 누르면 홈 화면으로 이동합니다.
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            버튼을 누르면 무료 인터넷을 사용할 수 있습니다.
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
});
