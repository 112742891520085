/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  placeLatLng: { ap: true, latitude: null, longitude: null },
};

export const placeSlice = createSlice({
  name: 'place',
  initialState,
  reducers: {
    setPlace: (state, action) => {
      state.placeLatLng = action.payload.placeLatLng;
    },
    getPlace: (state, action) => {
      const place = {
        placeLatLng: state.placeLatLng,
      };
      action.payload = place;
    },
    removePlace: state => {
      state.placeLatLng = initialState.placeLatLng;
    },
  },
});

// Action creators are generated for each case reducer function
export const placeAction = placeSlice.actions;

export default placeSlice.reducer;
